<template>
  <div class="book_page"
      v-loading='data_running'>
    <content-title :title="$t('message.novels')"></content-title>
    <!-- 顶部搜索 -->
    <div class="search flexCenter-jc-sb flexCenter-ai-center">
      <div class="input_box flexCenter-ai-center">
        <el-input
          v-model="book_name"
          :placeholder="$t('message.searchNovels')"
          spellcheck="“false”"
          @keyup.enter.native="setBookList"
        ></el-input>
        <div class="search_btn button-me" @click="setBookList">
          {{ $t("message.search") }}
        </div>
      </div>
      <div class="create_btn" @click="createLinkDialog({}, 1)">
        {{ $t("message.createLinkForTheBookStore") }}
      </div>
    </div>
    <!-- 书籍列表 -->
    <div  
      class="book_list_box" >
      <div class="book_list_content" v-if="bookArr.length > 0">
        <div class="book_title flexCenter-ai-center">
          <span style="width: 12%">{{ $t("message.trending") }}</span>
          <span style="width: 50.8%">{{ $t("message.novels") }}</span>
          <span style="width: 10.6%">{{ $t("message.category") }}</span>
          <span style="width: 9.8%">{{ $t("message.createdLinks") }}</span>
          <span style="width: 16.8%">{{ $t("message.link") }}</span>
        </div>
        <div class="book_list">
          <div
            class="book_item flexCenter-ai-center"
            v-for="(item, index) in bookArr"
            :key="item.book_id"
          >
            <span class="number" style="width: 12%"> {{ (data.page - 1) * data.limit + 1 + index}} </span>
            <div class="book" style="width: 50.8%" @click="nextBook(item.book_id)">
              <div class="img_box">
                <img :src="item.book_pic" alt="" />
                <span>{{
                  item.update_status == 1
                    ? $t("message.ended")
                    : $t("message.running")
                }}</span>
              </div>
              <div class="synopsis">
                <div class="name">{{ item.book_name }}</div>
                <div class="section flexCenter-ai-center">
                  <span>{{ $t("message.chapter") }} {{ item.section_num }}</span>
                  <span style="margin-left: 10px">
                    {{ item.word_num }}W{{ $t("message.words") }}</span
                  >
                </div>
                <!-- 小说简介 -->
                <div class="text">{{ item.book_desc }}</div>
              </div>
            </div>
            <span class="book_class" style="width: 10.6%">{{ item.category_name }}</span>
            <span style="width: 9.8%">{{ item.push_num }}</span>
            <div class="create_book_btn_box" style="width: 16.8%">
              <div
                class="create_book_btn button-me"
                @click="createLinkDialog(item, 2)"
              >
                {{ $t("message.createAReferralLink") }}
              </div>
            </div>
          </div>
        </div>
        <div class="pagination">
          <el-pagination
            background
            layout="prev, pager, next"
            @current-change="sizeChange"
            :hide-on-single-page="true"
            :total="count"
            :page-size="data.limit"
          >
          </el-pagination>
        </div>
      </div>
      <div class="empty flexCenter flex-column" v-if="is_books">
        <img src="@/assets/images/empty.png" alt="" />
        <span>{{ $t("message.noMoreData") }}</span>
      </div>
    </div>

    <!-- 创建链接 -->
    <el-dialog :visible.sync="book_channel_dialog" width="589px">
      <div class="title">{{ $t("message.createAReferralLink") }}</div>
      <div class="channel flex-align-items-flex-start">
        <span class="min_title">{{ $t("message.referralLink") }}: </span>
        <span class="val">{{create_item.book_name}}</span>
      </div>
      <div class="name">
        <span class="min_title">{{ $t("message.name") }}：</span>
        <el-input
          v-model="create_item.title"
          spellcheck="false"
          @keyup.enter.native="createLink"
        ></el-input>
      </div>
      <div class="btn button-me" @click="createLink">
        {{ $t("message.create") }}
      </div>
    </el-dialog>
    <!-- 创建链接成功 -->
    <el-dialog :visible.sync="channel_dialog" width="589px">
      <div class="title">{{ $t("message.linkCreated") }}</div>
      <div class="link">
        <div class="hint">{{ $t("message.CopyLink") }}</div>
        <!-- <input type="text" class="input-me" /> -->
        <el-input
          placeholder="请输入内容"
          v-model="link"
          :disabled="true">
        </el-input>
      </div>
      <div class="copy_btn btn button-me" @click="copy(link)">
        {{ $t("message.copy") }}
      </div>
    </el-dialog>
  </div>
</template>
<script>
import contentTitle from "@/components/contentTitle/contentTitle"; //顶部栏
import { bookList, generateLink } from "@/apis/api";
export default {
  name: "books",
  data() {
    return {
      book_name: "",
      count: 0,
      data: {
        page: 1,
        limit: 20,
      },
      bookArr: [],
      is_books: false, // 是否加载无数据布局
      data_running: false, // 加载数据
      create_item: {
        book_name: 'the book store', // 书城为首页, 其他单本链接为小说书名
        title: 'the book store', // 链接名称 可更改
        book_id: '', // 书籍ID
        link_type: '', // 生成链接类型，1=首页，2=单本,
        book_type: '', // 书籍类型 1=小说，2=漫画
      }, // 创建书籍弹框的数据(改小说创建的数据)
      link: '', // 创建成功的链接
      book_channel_dialog: false, // 创建书籍连接
      channel_dialog: false, // 创建书籍连接成功
      is_create: true, // 是否发送请求 开关
    };
  },
  mounted() {
    this.setBookList();
  },
  methods: {
    // 分页
    sizeChange(v) {
      this.data.page = v;
      this.setBookList();
    },
    // 跳转单本详情
    nextBook(book_id) {
      // window.open(window.location.host + '/#/book')
      window.open(`/#/book/${book_id}`);
      // this.$router.push(
      //   {
      //     path: '/book'
      //   }
      // )
    },
    // 打开创建链接弹框
    createLinkDialog(item, type) {
      this.book_channel_dialog = true
      this.create_item = {
        book_name: type == 1 ? this.$t('message.theBookStore') : item.book_name, // 书城为首页, 其他单本链接为小说书名
        title: type == 1 ? this.$t('message.theBookStore') : item.book_name, // 链接名称 可更改
        book_id: item.book_id, // 书籍ID
        link_type: type, // 生成链接类型，1=首页，2=单本,
        book_type: item.book_type, // 书籍类型 1=小说，2=漫画
      }
    },
    // 复制
    copy(val) {
      var input = document.createElement("input");
      input.value = val;
      document.body.appendChild(input);
      input.select();
      input.setSelectionRange(0, input.value.length), document.execCommand('Copy');
      document.body.removeChild(input);
      this.channel_dialog = false
      this.$message({
        message: this.$t('message.copied'),
        type: 'success'
      })
    },
    // 获取数据列表
    async setBookList() {
      this.data_running = true
      let params = {
        keyword: this.book_name,
        page: this.data.page,
        limit: this.data.limit,
      };
      let res = await bookList(params);
      this.data_running = false
      if (res.code === 1) {
        this.count = res.count;
        console.log(this.count)
        this.bookArr = res.data;
        res.data.length > 0 ? this.is_books = false : this.is_books = true
      }
      console.log(res);
    },
    // 创建推广链接
    async createLink() {
      if (!this.is_create) {
        return
      }
      this.is_create = false
      let res = await generateLink(this.create_item)
      if (res.code === 1) {
        this.link = res.data
        this.book_channel_dialog = false
        this.channel_dialog = true
        this.is_create = true
      }
      console.log(res)
    }
  },
  components: {
    contentTitle,
  },
};
</script>
<style lang="scss" scoped>
.book_page {
  .search {
    .input_box {
      >::v-deep.el-input {
        width: 288px;
        height: 44px;
        border: 1px solid #3d76ff;
        border-radius: 6px 0 0 6px;
        // padding-left: 16px;
        .el-input__inner {
          height: 100%;
          border: 0px solid #3d76ff;
        }
      }
      input::-webkit-input-placeholder {
        color: #9497a1;
        font-size: 14px;
      }
      .search_btn {
        cursor: pointer;
        // width: 80px;
        padding: 0 20px;
        height: 44px;
        text-align: center;
        line-height: 44px;
        font-size: 14px;
        color: #fff;
        background-color: #3d76ff;
        border: 1px solid #3d76ff;
        border-radius: 0px 6px 6px 0;
      }
    }
    .create_btn {
      // width: 210px;
      padding: 0 20px;
      height: 44px;
      text-align: center;
      line-height: 44px;
      font-size: 14px;
      color: #fff;
      background: #3d76ff;
      border-radius: 6px;
      cursor: pointer;
    }
    .create_btn:hover {
      background-color: #2c64e9;
    }
  }

  .book_list_box {
    margin-top: 30px;
    border-radius: 10px;
    background: #ffffff;
    // height: 600px;
    .book_title {
      height: 60px;
      > span {
        text-align: center;
        color: #15161a;
        font-size: 14px;
        font-weight: 600;
      }
    }
    .book_list {
      // height: 500px;
      // overflow-x: hidden;
      // overflow-y: scroll;
      line-height: 30px;
      .book_item {
        background-color: #ffffff;
        padding: 26px 0;
        > span {
          text-align: center;
          font-size: 14px;
          color: #000;
          font-weight: 400;
        }
        .number {
          font-size: 20px;
          color: #32343a;
        }
        .book {
          display: flex;
          > .img_box {
            position: relative;
            img {
              width: 120px;
              height: 160px;
              border-radius: 6px;
              border-color: pink;
            }
            span {
              position: absolute;
              right: 0;
              top: 0;
              width: 60px;
              height: 25px;
              line-height: 25px;
              text-align: center;
              font-size: 12px;
              color: #fff;
              background: #00c3a9;
              border-radius: 0px 5px 0px 8px;
            }
          }
          .synopsis {
            margin-left: 22px;
            .name {
              color: #15161a;
              font-size: 16px;
              font-weight: 500;
              margin-top: 14px;
            }
            .section {
              margin-top: 10px;
              color: #65676e;
              font-size: 14px;
              font-weight: 400;
            }
            .text {
              margin-top: 10px;
              color: #65676e;
              font-size: 14px;
              font-weight: 400;
              line-height: 24px;

              min-width: 200px;
              height: 48px;

              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box; //作为弹性伸缩盒子模型显示。
              -webkit-box-orient: vertical; //设置伸缩盒子的子元素排列方式--从上到下垂直排列
              -webkit-line-clamp: 2; //显示的行
            }
          }
        }
        .create_book_btn_box {
          text-align: center;
          .create_book_btn {
            cursor: pointer;
            padding: 0 20px;
            height: 40px;
            display: inline-block;
            text-align: center;
            line-height: 40px;
            color: #fff;
            font-size: 14px;
            background: #3d76ff;
            border-radius: 6px;
            margin: 0 auto;
          }
        }
      }
      .book_item:nth-child(odd) {
        background-color: #f9f9f9;
      }
      .book_item:hover {
        background-color: #f3f6ff;
      }
    }
    .book_list::-webkit-scrollbar {
      display: none;
    }

    .pagination {
      padding-bottom: 40px;
      margin-top: 30px;
    }
    .empty {
      height: 500px;
      border-radius: 10px;
      background: #ffffff;
      margin-top: 30px;
      img {
        width: 140px;
      }
      span {
        color: #9497a1;
        font-size: 14px;
      }
    }
  }

  .el-dialog__body {
    .title {
      color: #15161a;
      font-size: 24px;
      text-align: center;
      font-weight: 500;
      margin-top: 20px;
    }
    .channel {
      margin-left: 68px;
      margin-top: 42px;
      .min_title {
        color: #32343a;
        font-size: 16px;
        font-weight: 400;
      }
      .val {
        width: 268px;
        // background-color: red;
        display: inline-block;
        color: #15161a;
        font-size: 16px;
        font-weight: 500;
        margin-left: 14px;

        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        overflow: hidden;
        /*! autoprefixer: off */
        -webkit-box-orient: vertical;
      }
    }
    .name {
      margin-left: 68px;
      margin-top: 25px;
      .min_title {
        color: #32343a;
        font-size: 16px;
        font-weight: 400;
      }
      >::v-deep.el-input {
        width: 300px;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        margin-left: 14px;
        // padding-left: 16px;
        .el-input__inner {
          height: 100%;
          border: 0px solid #3d76ff;
        }
      }
      input::-webkit-input-placeholder {
        color: #9497a1;
        font-size: 14px;
      }
    }

    .btn {
      cursor: pointer;
      margin: 0 auto;
      margin-top: 40px;
      text-align: center;
      width: 200px;
      height: 46px;
      line-height: 46px;
      background: #3d76ff;
      color: #ffffff;
      border-radius: 8px;
    }

    .link {
      width: 462px;
      margin: 0 auto;
      margin-top: 42px;
      .hint {
        color: #65676e;
        font-size: 16px;
        font-weight: 400;
        line-height: 28px;
      }
      > input {
        width: 100%;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        padding-left: 16px;
        margin-top: 16px;
        color: #9497a1;
      }
      
      >::v-deep.el-input {
        width: 100%;
        height: 44px;
        border: 1px solid #c9ccd6;
        border-radius: 6px;
        margin-top: 18px;
        color: #9497a1;
        .el-input__inner {
          height: 100%;
        }
      }
      input::-webkit-input-placeholder {
        color: #9497a1;
        font-size: 14px;
      }
    }
    .copy_btn {
      margin-top: 46px;
    }
  }
}
</style>
<style lang="scss">
.book_page {
  .el-dialog {
    border-radius: 10px !important;
    .el-dialog__header {
      padding: 10px 10px 10px;
      .el-icon {
        font-size: 24px;
      }
    }
    .el-dialog__body {
      padding: 0;
      padding-bottom: 40px;
    }
  }
}
</style>
